import { Controller } from 'stimulus';
import steven from 'helpers/steven';

export default class extends Controller {
  static values = { tagId: String }

  connect() {
    this.sendStevenEvent()
  }

  sendStevenEvent() {
    steven.entityCrud({
      entity_id: this.tagIdValue,
      entity_type: 'tag',
      action: 'created',
    });
  }
}
