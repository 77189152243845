import { Controller } from 'stimulus';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    this.select2Mount();

    document.addEventListener('turbo:before-cache', this.handleCache);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.handleCache);
  }

  handleCache = () => {
    this.saveState();
    this.select2Unmount();
  };

  select2Mount() {
    const placeholder = this.select.data('placeholder');
    const options = {};

    if (placeholder) {
      options.placeholder = placeholder;
    }

    this.select.select2(options);
  }

  select2Unmount() {
    this.select.select2('destroy');
  }

  saveState() {
    const values = this.select.val();

    if (!values) {
      return;
    }
    if (Array.isArray(values)) {
      values.forEach((val) => {
        // Store selected in HTML before caching so select2 will re-init properly
        this.select.find(`option[value="${val}"]`).attr('selected', 'selected');
      });
    } else {
      this.select
        .find(`option[value="${values}"]`)
        .attr('selected', 'selected');
    }
  }

  get select() {
    return $(this.selectTarget);
  }
}
