import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = { url: String };

  fetchSubregions(event) {
    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: { country: event.target.value },
    });
  }
}
