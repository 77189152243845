import { Controller } from 'stimulus';
import CCEvents, {
  EventNames,
  EventPropertyKeys,
  EventPropertyValues,
} from '@companycam/companycam-events';

export default class extends Controller {
  static values = { triggerCollabSignup: Boolean }

  connect() {
    if (this.triggerCollabSignupValue) {
      this.sendCompleteRegistrationEvent()
    }
  }

  sendCompleteRegistrationEvent() {
    CCEvents.logEvent(EventNames.COMPLETE_REGISTRATION, {
      [EventPropertyKeys.REGISTRATION_METHOD]:
        EventPropertyValues.PROJECT_COLLABORATION,
    });
  }
}
