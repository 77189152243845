import { Controller } from 'stimulus';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

export default class extends Controller {
  static targets = ['editor', 'input'];

  connect() {
    let config = {
      mode: this.data.get('mode') || 'code',
    };

    if (this.targets.has('input')) {
      config.onChange = () => {
        try {
          const value = this.editor.get();
          this.inputTarget.value = JSON.stringify(value);
        } catch (e) {}
      };
    }

    this.editor = new JSONEditor(this.editorTarget, config);
    this.editorTarget.jsoneditor = this.editor;

    if (this.data.get('readOnly') === 'true') {
      this.editor.aceEditor.setReadOnly(true);
    }

    try {
      if (this.data.has('json')) {
        this.editor.set(JSON.parse(this.data.get('json')));
      } else if (this.targets.has('input')) {
        this.editor.set(JSON.parse(this.inputTarget.value));
      }
    } catch (e) {}
  }

  disconnect() {
    this.editor.destroy();
  }
}
