import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['input', 'loadingIndicator', 'mainContents'];

  initialize() {
    let delay = 350;
    if (this.data.has('submitDelay')) {
      delay = parseInt(this.data.get('submitDelay'));
    }
    this.submitForm = debounce(this.submitForm, delay);
  }

  connect() {
    if (this.targets.has('input')) {
      this._moveCursorToEnd();
    }

    if (this.targets.has('loadingIndicator')) {
      this.loadingIndicatorTarget.style.display = 'none';

      if (this.targets.has('mainContents')) {
        this.mainContentsTarget.style.display = 'initial';
      }
    }
  }

  submitForm = () => {
    if (this.targets.has('loadingIndicator')) {
      this.loadingIndicatorTarget.style.display = 'flex';

      if (this.targets.has('mainContents')) {
        this.mainContentsTarget.style.display = 'none';
      }
    }

    this.inputTarget.form.requestSubmit();
  };

  _moveCursorToEnd = () => {
    const val = this.inputTarget.value;
    if (val.length) {
      this.inputTarget.selectionStart = this.inputTarget.selectionEnd =
        val.length;
    }
  };
}
