import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['active'];

  static values = {
    open: Boolean,
  };

  connect() {
    document.addEventListener('turbo:before-cache', this._hide);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this._hide);
  }

  openValueChanged() {
    this.showMenuBasedOnOpenValue();
  }

  showMenuBasedOnOpenValue() {
    if (this.openValue) {
      this._show();
    } else {
      this._hide();
    }
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  hide(event) {
    if (this.openValue && !this.element.contains(event.target)) {
      this.openValue = false;
    }
  }

  _show() {
    this.element.classList.add(this.activeClass);
  }

  _hide = () => {
    this.element.classList.remove(this.activeClass);
  };
}
