import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['source'];
  }

  async copy() {
    const content = this.sourceTarget.textContent;

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(content);
    }
  }
}
