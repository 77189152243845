import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dateInput'];

  submit() {
    this.dateInputTarget.form.requestSubmit();
  }

  previous() {
    this.dateInputTarget.stepDown();
    this.submit();
  }

  next() {
    this.dateInputTarget.stepUp();
    this.submit();
  }
}
