import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["animation"];

  static values = { url: String }

  connect() {
    if (this.hasAnimationTarget) {
      // eslint-disable-next-line no-undef
      lottie.loadAnimation({
        container: this.animationTarget,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: this.urlValue
      });
    }
  }
}