import { Controller } from 'stimulus';
import CCEvents, {
  EventNames,
  EventPropertyKeys,
} from '@companycam/companycam-events';

export default class extends Controller {
  static get values() {
    return {
      planIdentifier: String,
    };
  }

  logAcceptInviteEvent() {
    const plan = this.planIdentifierValue;
    CCEvents.logEvent(EventNames.ACCEPT_COLLABORATION_INVITE, {
      [EventPropertyKeys.SUBSCRIPTION_PLAN]: plan,
    });
  }
}
