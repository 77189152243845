import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.timeout = setTimeout(this.removeFlash, 3500);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  removeFlash = () => {
    this.element.parentNode.removeChild(this.element);
  };
}
