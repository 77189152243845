import { Controller } from 'stimulus';
import steven, { ELEMENT_TYPES } from 'helpers/steven';


export default class extends Controller {
  logAcceptInviteClicked() {
    steven.elementClicked({
      id: 'accept-collaboration-button',
      content: 'Accept and Start Collaboration',
      type: ELEMENT_TYPES.BUTTON,
      action: 'accept_invite_confirm_button_clicked',
    });
  }
}
